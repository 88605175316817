import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Text, Wrapper } from '../../components/Ui';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

const CustomBox = styled(Box)`
  position: relative;
  border: none;

  padding: 0;
  overflow: hidden;
  width: 100%;

  img {
    border-radius: ${({ theme }) => theme.border_radius_generale_grande};
    max-width: 100%;
    display: block;
    width: auto;
    margin: 0 auto;
  }
`;
const CustomText = styled(Text)`
  position: absolute;
  top: 20%;
  left: 10%;
`;
const Overlay = styled.div`
  position: absolute;
  background-color: #00000026;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* padding: 15% 50% 0 10%; */
  display: flex;
  align-items: center;
  padding: 0 50% 0 10%;
`;
function NotificationDetail() {
  const { id } = useParams();
  const notif = useSelector(state => state.news.news?.find(item => +item.id === +id));

  /* useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  return (
    <Wrapper>
      <CustomBox style={{ border: 'none', backgroundColor: 'transparent' }}>
        <img src={notif?.image_big} alt='' />
      </CustomBox>

      <Box padding='20px 5%' style={{ border: 'none', backgroundColor: 'transparent' }}>
        <Text capit style={{ marginBottom: '15px' }} align='center'>
          {notif?.title}
        </Text>
        <Text align='center'>{notif?.content}</Text>
      </Box>
      {notif?.video_code && (
        <div style={{ padding: '20px 5%' }}>
          <Text bold capit style={{ marginBottom: '15px' }}>
            {notif?.video_title}
          </Text>
          <iframe
            width='100%'
            height='600'
            src={`https://www.youtube.com/embed/${notif.video_code}`}
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      )}
    </Wrapper>
  );
}

export default NotificationDetail;
