import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, Logo, Text } from '../Ui';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import MenuLateral from '../MenuLateral';
import { respondTo } from '../../theme/mixin';
import { useHistory } from 'react-router-dom';

const HeaderSection = styled.header`
  .p_mobile {
    width: 100%;
    display: block;
    margin: 20px 0;
    ${respondTo.md`
    display: none;
  `}
  }
  .p_desk {
    display: none;
    ${respondTo.md`
    display: block;
  `}
  }
  background-color: ${({ theme }) => theme.bg_general};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  //padding: 0 20px;
  padding-bottom: 10px;
  ${respondTo.sm`
    margin-left: 0;
    width: 100%;
    position: static;
    padding: 0;
    padding-bottom:10px;
  `}

  .accedi-wrapper {
    display: flex;
    align-items: center;
  }

  .la-festa-del-mare {
    text-align: center;
    padding-right: 20px;

    &.only-desktop {
      display: none;

      ${respondTo.sm`
        display: flex;
      `}
    }

    &.only-mobile {
      display: block;
      padding-right: 0;
      padding-bottom: 10px;

      span {
        white-space: normal;
      }

      ${respondTo.sm`
        display: none;
      `}
    }

    span {
      white-space: nowrap;
      color: #fff;
      padding-right: 5px;
    }

    a {
      color: #fff;
    }

    strong,
    a {
      display: inline-block;
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  height: ${props => props.theme.header_mobile_height + 'px'};
  background-color: ${({ theme }) => theme.bg_header};
  ${respondTo.sm`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
  `}
`;

const LogoNavContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const NavContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  justify-content: flex-end;
  top: 100%;
  left: 0;
  width: 100%;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.bg_general};
  border-top: 1px solid #dce4e8;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 0 0 10px 10px;

  ${respondTo.sm`
  margin-right: 0;
		display: flex;
    background-color: transparent;
    top: 0;
    width:fit-content;
    position: static;
    border-top: none;
    box-shadow: none;
	`}
`;

const Header = props => {
  //const { logo, logout, isLogged, multipromo, i18l } = props;
  const { logout, isLogged, multipromo, i18l } = props;

  const games = useSelector(state => state.game.games);
  const { logo, show_logout } = useSelector(state => state.app.config || {});
  const appName = useSelector(state => state.app.config.name);
  const headerContent = useSelector(state => state.cms.home);
  const headerTitle = headerContent?.[0]?.contents.find(el => el.name === 'header_title');
  const [idGame, setIdGame] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hideBurger, setHideBurger] = useState(false);
  const history = useHistory();
  const [isCatalog, setIsCatalog] = useState(false);

  useEffect(() => {
    if (games?.length === 1) {
      setIdGame(games[0].id);
    }
  }, [games]);

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer>
          <LogoNavContainer>
            <Logo logo={logo} marginLeft='0' />
            {!isLogged && <Text className='p_desk'>{headerTitle?.i18l?.content}</Text>}
            <NavContainer isOpen={isMenuOpen}>
              <NavigationItems
                isLogged={isLogged}
                multipromo={multipromo}
                i18l={i18l}
                idGame={idGame}
                logout={logout}
                setIsMenuOpen={setIsMenuOpen}
                setHideBurger={setHideBurger}
              />
            </NavContainer>
          </LogoNavContainer>
          <MenuLateral
            {...props}
            toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
            isMenuOpen={isMenuOpen}
            hideBurger={hideBurger}
          />
        </HeaderContainer>
        {!isLogged && (
          <Text align='center' className='p_mobile'>
            {headerTitle?.i18l?.content}
          </Text>
        )}

        {appName === 'La Festa del Mare' && !isLogged && (
          <div className='la-festa-del-mare only-mobile'>
            <span>servizio clienti:</span>
            <a href='mailto:lafestadelmare2022@coromarketing.it'>
              lafestadelmare2022@coromarketing.it
            </a>
          </div>
        )}
      </Wrapper>
    </HeaderSection>
  );
};

Header.propTypes = {
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
