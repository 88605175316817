import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text, Flex, Col, Button } from '../Ui';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { ReactComponent as Face } from '../../assets/images/facebook.svg';
import { ReactComponent as Insta } from '../../assets/images/instagram.svg';
import { ReactComponent as In } from '../../assets/images/linkedin.svg';
import { ReactComponent as Tik } from '../../assets/images/tiktok.svg';
import { ReactComponent as Twit } from '../../assets/images/twitter.svg';
import { ReactComponent as Yout } from '../../assets/images/youtube.svg';
import { ReactComponent as Gmail } from '../../assets/images/gmail.svg';
import { ReactComponent as Phon } from '../../assets/images/phon.svg';
import { ReactComponent as ToUp } from '../../assets/images/toUp.svg';
import points from '../../assets/images/points.png';
import pencil from '../../assets/images/pencil.png';
import radice from '../../assets/images/rad.png';
import croce from '../../assets/images/CROCE.png';
import globo from '../../assets/images/GLOBO.png';
import prev from '../../assets/images/Oggetto_vettoriale_avanzato_copia_8 (1).png';
import next from '../../assets/images/Oggetto_vettoriale_avanzato_copia_8.png';
import { Link } from 'react-router-dom';
import img from '../../assets/images/bggame.jpg';
import { respondTo } from '../../theme/mixin';

const FooterContainer = styled.footer`
  /* margin-top: 30px;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande}
    ${({ theme }) => theme.border_radius_generale_grande} 0 0;
  overflow: hidden;
  position: relative; */
`;

const TopBar = styled.div`
  padding: 5px 10px;

  ${respondTo.md`
  padding: 10px 40px;
  `}
  .supportTime {
    /* border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe; */
    padding: 10px;
    margin-top: 80px !important;
    text-align: center;
    margin: 15px 0 40px 0;
  }
  .content > * {
    font-weight: bold;
  }
  .flex {
    margin-top: 20px;
  }
  .col2 {
    padding: 5px 0;
    border-right: none;
    margin-bottom: 50px;
    ${respondTo.md`
      border-right:  ${({ theme, emailExists }) => (emailExists ? ' 2px solid #ffd0f4' : '')};
      margin-bottom: 0;

  `};
  }
  .col1 {
    padding: 5px 0;
    > div {
      height: 100%;
    }
    img {
      width: 200px;
      margin-top: 40px;
    }
  }
  .col2 {
    img {
      width: 150px;
    }
  }
  .col1,
  .col2 {
    img {
      /* width: 80px; */
    }
  }
`;

const CustomFlex = styled(Flex)`
  svg {
    margin-right: 10px;
    font-size: 40px;
    color: ${props => props.color};
    width: ${({ gmail }) => (gmail ? '35px' : '22px')};
    height: ${({ gmail }) => !gmail && '22px'};
    ${respondTo.md`
    height: initial;
    width: ${({ gmail }) => (gmail ? '55px' : '30px')};
  `}
  }
  p > p {
    font-size: 18px !important;
  }

  .textSize {
    a {
      font-size: 14px;
    }
  }
  ${respondTo.md`
     margin-bottom: 0;
    
     `}
`;

const BottomBar = styled.div`
  //background-color: ${({ theme }) => theme.bg_footer_basso};
  background-color: #3ab3bf;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  /* padding: 15px; */
  .links {
    color: ${({ linkColor }) => linkColor};

    a {
      color: ${({ linkColor }) => linkColor};
    }
  }
  svg {
    width: 20px;
    margin-right: 10px;
  }
  .btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    svg {
      width: 30px;
    }
  }
  ${respondTo.sm`
    svg {
    width: 30px;
    margin-right: 20px; 
  }
    `};
`;
const Social = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 40px;
  }
  svg {
    width: 40px;
    margin: 0;
  }
`;

const IconUp = styled(ToUp)`
  cursor: pointer;
  background-color: ${props => props.theme.bg_bottoni};
  color: ${props => props.theme.colore_testo_bottoni};
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  margin-left: auto;
  display: block;
  ${respondTo.sm`
 margin-left: 0;
  `};
`;

const MiddleBar = styled.div`
  position: relative;
  background-image: ${({ bg }) => 'url(' + bg + ')'};
  background-size: cover;
  background-position: 0 -200px;
  background-repeat: no-repeat;
  padding: 30px 0;
  ${respondTo.sm`
  //padding: 300px 0;
  `};
  .footer-content {
    text-align: center;
    font-size: 20px;
    color: #5dacbb;
    position: absolute;
    top: 47%;
    left: 48%;
    transform: translateX(-30%);
    ${respondTo.sm`
     top: 57%;
    left: 50%;
    font-size: 30px;
  `};
  }
  img {
    display: block;
  }
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 373px;
    position: relative;
    margin: 0 auto;
    position: relative;
    .icons {
      position: absolute;

      top: 20%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      & > img {
        width: 55px;
      }

      ${respondTo.md`
        &>img:nth-child(1){
          width:130px;
        }
        &>img:nth-child(2){
          width:95px;
        }
        &>img:nth-child(3){
          width:95px;
        }
        &>img:nth-child(4){
          width:107px;
        }
`};
    }
    .institution_count {
      color: #5dacbb;
      position: absolute;
      font-size: 65px;
      top: 32%;
      left: 40%;
      ${respondTo.md`
      font-size:100px;
      top: 40%;
      left: 47%;
`};
    }
    ${respondTo.md`
      width:100%;
  `};
    button {
      margin-top: 40px;
    }
  }
  .imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > img {
      width: 55px;
    }
    & > img:nth-child(2) {
      width: 150px;
    }
    ${respondTo.md`
       & > img:first-child,
    & > img:last-child {
      width: 130px;
    }
    & > img:nth-child(2) {
      width: 250px;
    }
  `};
  }
`;

const Footer = props => {
  const footercontent = useSelector(state => state.cms.footer);
  const fundraising_institution_count = useSelector(
    state => state.app.config.fundraising_institution_count,
  );

  const footerEmail = footercontent[0].contents.find(el => el.name === 'footer_email');
  const footerTel = footercontent[0].contents.find(el => el.name === 'footer_tel');
  const footerDetails1 = footercontent[0].contents.find(el => el.name === 'footer_details1');
  const footerContent = footercontent[0].contents.find(el => el.name === 'footer_content');
  const footerLinks = footercontent[0].contents.find(el => el.name === 'footer_links');
  const footerLogo = footercontent[0].contents.find(el => el.name === 'footer_logo');
  const footerPartners = footercontent[0].contents.find(el => el.name === 'footer_partners');
  const footerMiddleText = footercontent[0].contents.find(el => el.name === 'footer_middle_text');
  const footerTitle = footercontent[0].contents.find(el => el.name === 'footer_title');
  const footerBottomText = footercontent[0].contents.find(el => el.name === 'footer_bottom_text');
  const footerOrario = footercontent[0].contents.find(el => el.name === 'footer_orario');
  const { footerSettings } = useSelector(
    state => JSON.parse(state.app.config.settings.option) || {},
  );

  const socials = {
    facebook: <Face />,
    instagram: <Insta />,
    linkedin: <In />,
    tiktok: <Tik />,
    youtube: <Yout />,
    twitter: <Twit />,
  };
  return (
    <>
      <FooterContainer>
        <MiddleBar bg={footerContent?.img}>
          <div className='content'>
            {fundraising_institution_count > 0 && (
              <Text
                bold
                as='strong'
                style={{ textAlign: 'center' }}
                className='institution_count'
                //color={footerContent?.text_color}
                white
              >
                {fundraising_institution_count}
              </Text>
            )}
            {footerContent?.i18l.content && (
              <Text
                as='p'
                style={{ textAlign: 'center', marginBottom: '100px' }}
                /* color={footerContent?.text_color} */ white
              >
                {footerContent?.i18l.content}
              </Text>
            )}
            {footerContent?.show_cta && (
              <Button active>
                <Link to={footerContent?.cta_url}>{footerContent?.cta_label}</Link>
              </Button>
            )}
            <Wrapper className='icons'>
              <img src={globo} alt='' />
              <img src={next} alt='' />
              <img src={prev} alt='' />
              <img src={croce} alt='' />
            </Wrapper>
          </div>
          <Wrapper className='imgs'>
            <img src={pencil} alt='' />
            <img src={points} alt='' />
            <img src={radice} alt='' />
          </Wrapper>
        </MiddleBar>
        <TopBar
          emailExists={footerEmail?.i18l.content && footerEmail?.i18l.content !== '<p>&nbsp;</p>'}
        >
          {footerTitle?.i18l.content && (
            <Text align='center' /* color={footerTitle?.text_color} */ margin='20px 0' white>
              {footerTitle?.i18l.content}
            </Text>
          )}
          <Flex justify='center' align='stretch' className='flex' style={{ marginTop: '80px' }}>
            {footerTel?.i18l.content && Boolean(footerTel?.i18l.content !== '<p>&nbsp;</p>') && (
              <Col padding='0' className='col2' width={30}>
                <Flex direction='column' align='center' gap='10px'>
                  {footerTel?.img ? <img src={footerTel?.img} alt='' /> : <Phon phon />}{' '}
                  <Text as='p' size={18} white bold>
                    {footerTel?.i18l.title}
                  </Text>
                  <Text as='p' size={14} className='content' color='#f9ffbd' bold>
                    {footerTel?.i18l.content}
                  </Text>
                </Flex>
              </Col>
            )}
            {footerEmail?.i18l.content && footerEmail?.i18l.content !== '<p>&nbsp;</p>' && (
              <Col padding='0' className='col1' width={30}>
                <Flex direction='column' align='center' gap='10px'>
                  {footerEmail?.img ? <img src={footerEmail?.img} alt='' /> : <Gmail />}
                  <Text as='p' size={18} white bold style={{ marginTop: 'auto' }}>
                    {footerEmail?.i18l.title}
                  </Text>
                  <Text as='p' size={14} className='content' color='#f9ffbd' bold>
                    {footerEmail?.i18l.content}
                  </Text>
                </Flex>
              </Col>
            )}
          </Flex>
          {footerOrario?.i18l.content && (
            <Text className='supportTime' size={14}>
              {footerOrario?.i18l.content}
            </Text>
          )}
        </TopBar>
        <BottomBar linkColor={footerLinks?.text_color}>
          <Wrapper style={{ padding: '30px 0' }}>
            {footerLinks?.i18l.content && (
              <Text as='p' style={{ textAlign: 'center' }} className='links'>
                {footerLinks?.i18l.content}
              </Text>
            )}
            <Flex justify='center' style={{ marginTop: '15px' }} align='center'>
              <Col style={{ textAlign: 'center' }} width={80}>
                {footerMiddleText?.i18l.content && (
                  <Text size={12} color={footerMiddleText?.text_color}>
                    {footerMiddleText?.i18l.content}
                  </Text>
                )}
              </Col>
            </Flex>
          </Wrapper>
          {footerBottomText?.i18l.content && (
            <div style={{ backgroundColor: '#2a2d34', padding: '20px 10px' }}>
              <Wrapper>
                <Text bold color='#fff' size={14} align='center'>
                  {footerBottomText?.i18l.content}
                </Text>
                <div className='btn'>
                  <IconUp onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                </div>
              </Wrapper>
            </div>
          )}
        </BottomBar>
      </FooterContainer>
    </>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
