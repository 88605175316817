import React, { useState } from 'react';
import { Text, Wrapper } from '../../components/Ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

const Container = styled.div`
  .img1 {
    width: 50px;
    position: absolute;
    height: auto;

    bottom: -60px;
    left: -35px;
    z-index: -1;
    ${respondTo.sm`
       width: 100px;
       left: -115px;
    bottom: -50px;
   
  `}
  }
  .img2 {
    width: 60px;
    position: absolute;
    right: 0px;
    bottom: -30px;
    height: auto;
    z-index: -1;
    ${respondTo.sm`
       width: 120px;
       right: 0;
       bottom: -60px;
   
  `}
  }
  .title {
    font-family: 'Lobster', cursive;
    color: #fff;
    font-size: 90px;
    ${respondTo.sm`
     font-size: 150px;
  `}
  }
  .sub-title {
    color: #ffdbf4;
    font-size: 56px;
    font-family: 'Lobster', cursive;
    ${respondTo.sm`
    font-size: 100px;
  `}
  }
  .second-sub-title {
    font-size: 30px;
    color: #fff;
    font-family: 'Lobster', cursive;
    ${respondTo.sm`
    font-size: 63px;
  `}
  }
  .text-content {
    color: #fff;
    font-size: 20px;
    ${respondTo.sm`
    font-size: 33px;
  `}
  }
`;

function Participate() {
  const isLogged = useSelector(state => state.auth.token);
  const participate = useSelector(state => state.cms.howToParticipate);
  const home = useSelector(state => state.cms.home);

  const participate_title = participate[0].contents.find(el => el.name === 'participate_title');
  const participate_content = participate[0].contents.find(el => el.name === 'participate');
  const header_content = home[0].contents.find(el => el.name === 'header');
  const headertitoli1 = home[0].contents.find(el => el.name === 'headertitoli1');
  const logedTitle = home[0].contents.find(el => el.name === 'title-home');

  const [scrolledTo50, setScrolledTo50] = useState(false);

  useEffect(() => {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-12536644/todisbgs/tdsbgssc+standard',
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      // Check if the user has scrolled past 50%
      if (!scrolledTo50 && scrollTop > (scrollHeight - clientHeight) * 0.5) {
        // Track the custom event using ReactPixel
        window.gtag('event', 'conversion', {
          allow_custom_scripts: true,
          send_to: 'DC-12536644/todisbgs/tdsbgslp+standard',
        });

        setScrolledTo50(true); // Set state to prevent multiple triggers
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolledTo50]);
  /*   useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  return (
    <Container>
      {headertitoli1?.i18l.content && !isLogged && (
        <Text bold primary align='center'>
          {headertitoli1?.i18l.content}
        </Text>
      )}
      {logedTitle?.i18l.content && isLogged && (
        <Text bold primary align='center'>
          {logedTitle?.i18l.content}
        </Text>
      )}
      <Wrapper>
        {header_content?.i18l.content && (
          <Text bold primary align='center' style={{ padding: '0 40px 80px ' }}>
            {header_content?.i18l.content}
          </Text>
        )}
      </Wrapper>
      {(participate_title?.i18l.content || participate_content?.img) && (
        <ParticipateTop bg={participate_title?.background_color}>
          <Wrapper>
            {participate_title?.i18l.content && (
              <Text bold primary align='center'>
                {participate_title?.i18l.content}
              </Text>
            )}
          </Wrapper>
        </ParticipateTop>
      )}
      {participate_content?.i18l.content && (
        <ParticipateBottom bg={participate_content?.background_color}>
          <Wrapper>
            <Text as='p'>{participate_content?.i18l.content}</Text>
          </Wrapper>
        </ParticipateBottom>
      )}
    </Container>
  );
}
const ParticipateTop = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${({ bg }) => bg};
  img {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
`;
const ParticipateBottom = styled.div`
  background-color: ${({ bg }) => bg};
`;

export default Participate;
