import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Modal } from '../../components/Ui';
import { authInit, signinClearError } from '../../store/actions';
import ReactPixel from 'react-facebook-pixel';

function ExternalLogin() {
  const dispatch = useDispatch();
  const isLogged = useSelector(state => state.auth.token);
  const loading = useSelector(state => state.auth.loading);
  const error = useSelector(state => state.auth.error?.data?.error);
  const { token } = useParams();
  const history = useHistory();

  /* useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  useEffect(() => {
    dispatch(authInit(token));
  }, []);

  useEffect(() => {
    if (isLogged) {
      history.push('/');
    }
  }, [isLogged]);

  return (
    <>
      <Loader initial show={loading} />
      <Modal
        show={Boolean(error)}
        close={() => {
          dispatch(signinClearError());
          history.push('/');
        }}
        alert
      >
        {error}
      </Modal>
    </>
  );
}

export default ExternalLogin;
