import React, { useEffect } from 'react';
import { Text, Wrapper } from '../../components/Ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import ReactPixel from 'react-facebook-pixel';

const Container = styled.div`
  .wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    padding: 0;
    /* background-color: ${({ theme }) => theme.bg_general}; */
    ${respondTo.sm`
 padding: 0 15px;
  `}
  }
  .img1 {
    width: 50px;
    position: absolute;
    height: auto;

    bottom: -60px;
    left: -35px;
    z-index: -1;
    ${respondTo.sm`
       width: 100px;
       left: -115px;
    bottom: -50px;
   
  `}
  }
  .img2 {
    width: 60px;
    position: absolute;
    right: 0px;
    bottom: -30px;
    height: auto;
    z-index: -1;
    ${respondTo.sm`
       width: 120px;
       right: 0;
       bottom: -60px;
   
  `}
  }
  .title {
    font-family: 'Lobster', cursive;
    color: #fff;
    font-size: 90px;
    ${respondTo.sm`
     font-size: 150px;
  `}
  }
  .sub-title {
    color: #ffdbf4;
    font-size: 56px;
    font-family: 'Lobster', cursive;
    ${respondTo.sm`
    font-size: 100px;
  `}
  }
  .second-sub-title {
    font-size: 30px;
    color: #fff;
    font-family: 'Lobster', cursive;
    ${respondTo.sm`
    font-size: 63px;
  `}
  }
  .text-content {
    color: #fff;
    font-size: 20px;
    ${respondTo.sm`
    font-size: 33px;
  `}
  }
`;
function Tutorial() {
  const tutorial = useSelector(state => state.cms.tutorial);
  const isLogged = useSelector(state => state.auth.token);

  const tutorial_title = tutorial?.[0]?.contents.find(el => el.name === 'tutorial_title');
  const tutorial_content = tutorial?.[0]?.contents.find(el => el.name === 'tutorial');
  const home = useSelector(state => state.cms.home);

  const header_content = home[0].contents.find(el => el.name === 'header');
  const headertitoli1 = home[0].contents.find(el => el.name === 'headertitoli1');
  const logedTitle = home[0].contents.find(el => el.name === 'title-home');

  useEffect(() => {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-12536644/todisbgs/tdsbgssc+standard',
    });
  }, []);

  return (
    <Container>
      {headertitoli1?.i18l.content && !isLogged && (
        <Text bold primary align='center'>
          {headertitoli1?.i18l.content}
        </Text>
      )}
      {logedTitle?.i18l.content && isLogged && (
        <Text bold primary align='center'>
          {logedTitle?.i18l.content}
        </Text>
      )}
      <Wrapper>
        {header_content?.i18l.content && (
          <Text bold primary align='center' style={{ padding: '0 40px 80px ' }}>
            {header_content?.i18l.content}
          </Text>
        )}
      </Wrapper>
      {(tutorial_title?.i18l.content || tutorial_content?.img) && (
        <TutorialTop bg={tutorial_title?.background_color}>
          <Wrapper>
            {tutorial_title?.i18l.content && (
              <Text bold primary align='center'>
                {tutorial_title?.i18l.content}
              </Text>
            )}
          </Wrapper>
        </TutorialTop>
      )}
      {tutorial_content?.i18l.content && (
        <TutorialBottom bg={tutorial_content?.background_color}>
          <Wrapper>
            <Text as='p' style={{ padding: '0 15px' }}>
              {tutorial_content?.i18l.content}
            </Text>
          </Wrapper>
        </TutorialBottom>
      )}
    </Container>
  );
}
const TutorialTop = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${({ bg }) => bg};
  img {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
`;
const TutorialBottom = styled.div`
  iframe {
    width: 100%;
    ${respondTo.sm`
    width:70%;
  `}
  }
  padding: 30px 0;
  background-color: ${({ bg }) => bg};
`;

export default Tutorial;
