import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Flex, Modal, Text } from '../../components/Ui';
import { useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

const Container = styled(Box)`
  margin: 20px 0;
  .photos {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
  }
  .photo {
    img {
      border-radius: inherit;
      max-width: 100%;
      max-height: 211px;
      margin: 0 auto;
      display: block;
    }
    border: 1px solid ${({ theme }) => theme.colore_bordo_box};
    border-radius: 10px;
    padding: 5px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;

    .status {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      color: #fff;
      padding: 3px;
    }
    .approved {
      background-color: #050;
    }
    .rejected {
      background-color: #500;
    }
    .pending {
      background-color: #a29b00;
    }
  }
`;

function UserGallery() {
  const gallery = useSelector(state => state.user.user.ratephotos);
  const [showPhoto, setShowPhoto] = useState('');

  /* useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  const photoClickHandler = image => {
    setShowPhoto(image);
  };
  return (
    <>
      <div>
        {gallery?.length ? (
          gallery.map(({ game_id, game_name, photos }) => (
            <Container key={game_id}>
              <Text bold align='center'>
                {game_name}
              </Text>
              <div className='photos'>
                {photos.length ? (
                  photos.map(
                    ({ image, status, photo_date, store_name, status_label, rejected_reason }) => (
                      <div
                        className='photo'
                        key={image}
                        onClick={() => photoClickHandler({ image, reason: rejected_reason })}
                      >
                        <span className={`status ${status}`}>{status_label}</span>
                        <img src={image} alt='' />
                        <Flex gap={10} justify='space-between' align='center'>
                          <Text size={14}>{store_name}</Text>
                          <Text size={12}>
                            {new Intl.DateTimeFormat('it').format(new Date(photo_date))}
                          </Text>
                        </Flex>
                      </div>
                    ),
                  )
                ) : (
                  <Text align='center'>Nessuna foto trovata</Text>
                )}
              </div>
            </Container>
          ))
        ) : (
          <Text align='center'>Nessuna foto trovata</Text>
        )}
      </div>
      <Modal
        show={showPhoto}
        close={() => {
          setShowPhoto('');
        }}
      >
        {showPhoto.reason && (
          <Text size={16} margin='0 0 15px 0'>
            {showPhoto.reason}
          </Text>
        )}
        <img src={showPhoto.image} alt='' />
      </Modal>
    </>
  );
}

export default UserGallery;
