import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

const ValidateEmailApp = () => {
  const logo = useSelector(state => state.app.config.logo);

  /* useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  return (
    <ValidateContainer>
      <div>
        <img src={logo} alt='' />
        <h1>Account attivato correttamente</h1>
        <h3>Apri l'app per effettuare il login</h3>
      </div>
    </ValidateContainer>
  );
};

const ValidateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  div {
    text-align: center;

    img {
      margin-bottom: 15px;
      max-width: 400px;
    }

    h1 {
      margin-bottom: 15px;
    }
  }
`;

export default ValidateEmailApp;
