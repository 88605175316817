import React, { useState } from 'react';
import { respondTo } from '../../theme/mixin';
import { Button, Hamburger, SwitchLang, Text } from '../Ui';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoginBtn from '../LoginBtn';
import Logout from '../Logout';
import styled from 'styled-components';
import { Close } from '@styled-icons/material';
import { Menu } from 'styled-icons/boxicons-regular';
import UserProfile from '../../assets/images/userAvatar.svg';
import UserPopUp from '../UserPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { authInit } from '../../store/actions';
import { showAuthForm } from '../../store/actions/auth';
import { useHistory } from 'react-router-dom';
import { breakpoints } from '../../theme/_variables';

const MenuLateralContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MenuContainerDesk = styled.div`
  display: block;
  .profile {
    span {
      white-space: nowrap;
      display: none;
      transition: 0.4s;
      color: ${({ theme }) => theme.colore_testo_menu_esterno};

      ${respondTo.sm`
      display: block;

      `};
    }
    cursor: pointer;
    &:hover {
      .icon {
        background-color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
      }
      span {
        color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
      }
    }
    ${respondTo.sm`
   display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    z-index:1; 
  `};
  }
  ${respondTo.sm`
		display: block;
  `}
`;

const MenuContainerMobile = styled.div`
  display: flex;

  ${respondTo.sm`
		display: none;
	`}
`;
const AccediBtn = styled(Button)``;

const ImgProfile = styled.div`
  cursor: pointer;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  overflow: hidden;
`;
const MaskImg = styled.div`
  height: 50px;
  width: 50px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colore_testo_menu_esterno};
  -webkit-mask-image: url(${({ src }) => src});
  mask-image: url(${({ src }) => src});
  -webkit-mask-size: 100%;
  mask-size: 100%;
  z-index: -1;
`;

const MenuLateral = props => {
  const { toggleMenu, isMenuOpen, logout, isLogged, bootApp, langs, hideBurger } = props;

  const [t] = useTranslation();
  const [toggelDropDown, setToggelDropDown] = useState(false);
  const setIsLogin = useSelector(state => state.auth.show_auth_form);
  const appName = useSelector(state => state.app.config.name);
  const show_logout = useSelector(state => state.app.config.show_logout);
  const visible = useSelector(state => state.app.config.visible);
  const { firstname, lastname } = useSelector(state => state.user.user.userinfo || {});
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <MenuLateralContainer>
        <MenuContainerDesk>
          {isLogged ? (
            <>
              <div className='profile' onClick={() => setToggelDropDown(prev => !prev)}>
                <ImgProfile>
                  <MaskImg src={UserProfile} className='icon' />
                </ImgProfile>

                <Text size={12} upper>
                  {firstname}
                </Text>
              </div>
              {toggelDropDown && (
                <UserPopUp
                  setToggelDropDown={setToggelDropDown}
                  logout={logout}
                  show_logout={show_logout}
                />
              )}
            </>
          ) : (
            <div className='accedi-wrapper'>
              {appName === 'La Festa del Mare' && (
                <div className='la-festa-del-mare only-desktop'>
                  <span>servizio clienti - </span>
                  <a href='mailto:lafestadelmare2022@coromarketing.it'>
                    lafestadelmare2022@coromarketing.it
                  </a>
                </div>
              )}

              {show_logout ? (
                <AccediBtn
                  active
                  onClick={() => {
                    history.push('/auth/login');
                    dispatch(showAuthForm(!setIsLogin));
                  }}
                  style={{ minWidth: '235px', justifyContent: 'center' }}
                >
                  {visible ? 'ACCEDI / REGISTRATI' : 'ACCEDI'}
                </AccediBtn>
              ) : null}
            </div>
          )}
          {langs?.length > 1 ? <SwitchLang bootApp={bootApp} langs={langs} /> : null}
        </MenuContainerDesk>
        {isLogged && !hideBurger && (
          <MenuContainerMobile onClick={toggleMenu}>
            {isMenuOpen ? <Close size={45} /> : <Menu size={45} />}
          </MenuContainerMobile>
        )}
      </MenuLateralContainer>
    </>
  );
};

MenuLateral.propTypes = {
  toggleSidebar: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  ownLogin: PropTypes.number,
  oauths: PropTypes.array,
  oauthInfo: PropTypes.object,
  appInit: PropTypes.func,
  langs: PropTypes.array,
  oauth_profile_url: PropTypes.string,
};

export default MenuLateral;
