import React from 'react';
import { Col, Flex, Text, Wrapper } from '../../components/Ui';
import notif1 from '../../assets/images/notif1.png';
import notif2 from '../../assets/images/notif2.png';
import notif3 from '../../assets/images/notif3.png';
import styled from 'styled-components';
import { BsTrash } from 'react-icons/bs';
import { IoIosArrowDropright } from 'react-icons/io';
import { GoBook } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteSingleNews, getSingleNews, getAllNews } from '../../store/actions/news';
import { hexToRgba } from '../../utils/utils';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

const CustomFlex = styled(Flex)`
  padding: 10px;
  border: ${({ theme }) => '1px solid ' + theme.colore_bordo_box};
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  overflow: hidden;
  margin-bottom: 20px;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  ${respondTo.sm`
   border-top-right-radius: initial;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
     `};
`;
const Btn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color_icon_prd};
  font-size: 30px;
  position: absolute;
  top: 10px;
  cursor: pointer;
  right: 10px;
  svg {
    color: ${({ theme }) => theme.primary};
  }
`;
const CustonLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  svg {
    font-size: 30px;
    margin-left: 5px;
    color: ${({ theme }) => theme.primary};
  }
`;
const CustomCol = styled(Col)`
  height: 300px;

  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 10px;
  background-color: ${({ bg, theme }) => !bg && theme.bg_box};
  ${respondTo.sm`
     border-top-right-radius: 10px;
     border-bottom-left-radius: 0;
  `};
`;
const CustomText = styled(Text)`
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    font-size: 27px;
    color: ${({ theme }) => theme.primary};
  }
`;

function Notification() {
  useEffect(() => {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-12536644/todisbgs/tdsbgssc+standard',
    });
  }, []);

  const { news } = useSelector(state => state.news);
  const colore_testo_generale = useSelector(
    state => state.app?.config?.design?.template_props?.colore_testo_generale,
  );
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});

  const dispatch = useDispatch();
  const readNews = id => {
    dispatch(getSingleNews(id)).then(() => dispatch(getAllNews()));
  };
  const deleteNotif = id => {
    dispatch(deleteSingleNews(id)).then(() => dispatch(getAllNews()));
  };

  return news?.filter(item => item.visible_in_list)?.length > 0 ? (
    news
      ?.filter(item => item.visible_in_list)
      .map(item => {
        return (
          <Wrapper>
            <CustomFlex key={item.id} justify='center' align='stretch'>
              <Col width={50} padding='0' style={{ height: '300px' }}>
                <Image src={item.image_small} />
              </Col>
              <CustomCol
                width={50}
                bg={
                  item.bgcolor_dx && item.bgcolor_sx
                    ? `linear-gradient(to right, ${item.bgcolor_dx} 0%, ${item.bgcolor_sx} 100%)`
                    : null
                }
                padding='0 100'
                style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
              >
                {!item.read && (
                  <CustomText as='p' bold capit>
                    <GoBook color={item.text_color_list} />{' '}
                    <Text
                      text_red
                      bold
                      size={18}
                      capit
                      color={item.text_color_list ?? colore_testo_generale}
                    >
                      da leggere
                    </Text>
                  </CustomText>
                )}
                <Btn white={!item.dark_text} onClick={() => deleteNotif(item.id)}>
                  <BsTrash color={item.text_color_list ?? colore_testo_generale} />
                </Btn>
                <Text text_box color={item.text_color_list ?? colore_testo_generale}>
                  {item.title}
                </Text>
                <CustonLink to={'/notifications/' + item.id} onClick={() => readNews(item.id)}>
                  <Text
                    capit
                    size={18}
                    text_red
                    bold
                    primary
                    style={{ display: 'flex', alignItems: 'center' }}
                    color={item.text_color_list ?? colore_testo_generale}
                  >
                    vai{' '}
                    <IoIosArrowDropright color={item.text_color_list ?? colore_testo_generale} />
                  </Text>
                </CustonLink>
              </CustomCol>
            </CustomFlex>
          </Wrapper>
        );
      })
  ) : (
    <Wrapper>
      <Text as='h1' size={40} className='mb-20' align='center'>
        {option?.menuSettings?.news?.label}
      </Text>
      <Text align='center' size={18}>
        Non ci sono novità da mostrare
      </Text>
    </Wrapper>
  );
}

export default Notification;
