import React from 'react';
import { Input, Text, Wrapper } from '../../components/Ui';
import { registredSchoolsForm } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import { useEffect } from 'react';
import customAxiosNm from '../../config/axios-refresh-token';
import { useState } from 'react';
import Select from '../../components/Ui/Input/Select/Select';
import styled from 'styled-components';
import { getInstitutions } from '../../utils/utils';
import ReactPixel from 'react-facebook-pixel';

function RegisteredSchools() {
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [city, setCity] = useState('');

  /* useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  useEffect(() => {
    window.scrollTo(0, 0);
    customAxiosNm
      .get('/fundraising/get-beneficiaries-districts')
      .then(res => setDistricts(res.data));
  }, []);
  const districtChangeHandler = e => {
    setCities([]);
    setInstitutions([]);
    setSchools([]);

    if (e.target.value) {
      customAxiosNm
        .get(`/fundraising/get-beneficiaries-cities?district=${e.target.value}`)
        .then(res => setCities(res.data));
    }
  };
  const institutionChangeHandler = e => {
    setSchools([]);
    if (e.target.value) {
      customAxiosNm
        .get(`/fundraising/get-beneficiaries?city=${city}&institution_type=${e.target.value}`)
        .then(res => setSchools(res.data));
    }
  };
  const cityChangeHandler = e => {
    setSchools([]);
    setInstitutions([]);
    if (e.target.value) {
      setCity(e.target.value);
      getInstitutions(e.target.value).then(data => setInstitutions(data));
    }
  };

  return (
    <Wrapper>
      <Text primary bold align='center' style={{ marginBottom: '20px' }}>
        Scuole/Associazioni sportive
      </Text>
      <Select
        onChange={districtChangeHandler}
        name='district'
        style={{ margin: '20px 0', appearance: 'auto' }}
      >
        <option value=''>Scegli la provincia</option>
        {districts.map(d => (
          <option value={d}>{d}</option>
        ))}
      </Select>
      <Select onChange={cityChangeHandler} name='city' style={{ appearance: 'auto' }}>
        <option value=''>Scegli il comune</option>
        {cities.map(d => (
          <option value={d}>{d}</option>
        ))}
      </Select>
      <Select
        onChange={institutionChangeHandler}
        name='city'
        style={{ margin: '20px 0', appearance: 'auto' }}
      >
        <option value=''>Tipo di istituzione</option>
        {institutions.map(({ label, value }) => (
          <option value={value}>{label}</option>
        ))}
      </Select>
      <Table>
        <thead>
          <th>
            <Text bold>Istituto Comprensivo / Scuola</Text>
          </th>
          <th>
            <Text bold>Città</Text>
          </th>
        </thead>
        <tbody>
          {schools.map(({ firstname, city }) => (
            <tr>
              <td>{firstname}</td>
              <td>{city}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
}
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: white;
  }
`;

export default RegisteredSchools;
