import React, { useEffect } from 'react';
import CheckoutForm from '../../components/Checkout';
import { Modal, Text } from '../../components/Ui';
import { clearOrder, checkoutErrorClear } from '../../store/actions/products';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

function Checkout() {
  const { newOrder } = useSelector(state => state.product);
  const has_fee = useSelector(state => state.app?.config?.has_fee);
  const { error } = useSelector(state => state.products || {});
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkoutStep, setcheckoutStep] = useState(true);

  /* useEffect(() => {
    ReactPixel.pageView();
  }, []); */
  useEffect(() => {
    if (newOrder.completed) {
      dispatch(clearOrder());
      history.push('/order_completed');
    }
  }, [newOrder.completed]);

  return (
    <>
      <CheckoutForm nextStep={setcheckoutStep} />

      <Modal
        show={Boolean(error)}
        close={() => {
          dispatch(checkoutErrorClear());
          dispatch(clearOrder());
        }}
        alert
      >
        <Text>{error}</Text>
      </Modal>
    </>
  );
}

export default Checkout;
